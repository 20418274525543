import {
  Container, Row, Col, Figure, Image,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './footer.css';
import logo from '../assets/images/home/logo.png';

function Footer() {
  return (
    <footer>
      <div className="topFooter py-5">
        <Container>
          <Row>
            <Col sm={4}>
                <Link to={'/'} className="footerLogo">
                  <Figure><Image src={logo} alt="logo" /></Figure>
                </Link>
                <div className="logoContent">
                    <p>
                      Malance is dedicated to fostering mental well-being and productivity through
                      innovative tools and insights.
                    </p>
                </div>
            </Col>
            <Col sm={2} className="borderShap">
              <div className="ps-4">
                <h3 className="mb-2 fs-6 text-black">Our solutions</h3>
              </div>
              <ul className="menu ps-4">
                <li><Link to={'/auth/profilometer'}>Profilometer</Link></li>
                <li><Link to={'/auth/mentalbalance'}>Mental Balance</Link></li>
                <li><Link to={'/auth/workbalance'}>Work Balance</Link></li>
              </ul>
            </Col>
            <Col sm={2}>
              <div className="ps-4">
                <h3 className="mb-2 fs-6 text-black">Quick links</h3>
              </div>
              <ul className="menu ps-4">
                <li><Link to={'/'}>Home</Link></li>
                <li><Link to={'/therapy'}>Therapy</Link></li>
                <li><Link to={'/how-it-works'}>How It Works</Link></li>
                {/* <li><Link href="#">Blog</Link></li> */}
                <li><Link to={'/faq'}>FAQs</Link></li>
                <li><Link to={'/contact'}>Contact Us</Link></li>
              </ul>
            </Col>
            <Col sm={4}>
              <div className="address">
                <h3 className="mb-2 fs-6 text-black">Contact us</h3>
                <p className="mb-2">C 936, Gaur Green Avenue, Abhay Khand II,<br />
                  Indirapuram, UP- 201014
                </p>
                <p><Link to='mailto:care@malance.com'>care@malance.com</Link></p>
                <ul className="socialIcon">
                    <h3 className="pb-1 fs-6 text-black">Follow us</h3>
                    <li>
                      <Link href="#">
                        <i className="bi bi-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href="#">
                        <i className="bi bi-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      className="bi bi-twitter-x" viewBox="0 0 16 16">
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                      </svg>
                      </Link>
                    </li>
                    <li>
                      <Link href="#">
                        <i className="bi bi-whatsapp"></i>
                      </Link>
                    </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bottomFooter pb-3">
          <div className="container">
              <div className="row">
                  <div className="col-md-12 col-sm-12 text-center">
                      <p>Copyright © 2025 Malance. All rights reserved.</p>
                  </div>
              </div>
          </div>
      </div>
  </footer>
  );
}

export default Footer;
