import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container, Row, Col, Button, Form, FormGroup, FormControl, FormLabel, Spinner,
} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { sendResetPassword } from '../../services/AuthService';
import './login.css';

function ResetPassword() {
  const { token } = useParams(); // Extract token from URL
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  // Toggle password visibility
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword((prev) => !prev);

  const handleResetPassword = async (data) => {
    setIsLoading(true);
    try {
      const response = await sendResetPassword({ password: data.password, token });

      if (response.success) {
        localStorage.setItem('toastMessage', JSON.stringify({ variant: 'success', message: response.message }));
        navigate('/login');
      } else {
        toast.error(response.message || 'Failed to reset password. Please try again.');
      }
    } catch (error) {
      toast.error(error.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <main>
        <div className="resetPassword">
          <div className="resetForm loginForm backgroundBg py-5">
            <Container>
              <Row className="align-items-center">
                <Col sm={6}>
                  <h2 className="heading">Let’s empower</h2>
                  <h3 className="subHeading">your workforce</h3>
                  <p className="para">
                    Access tools and insights designed to enhance mental resilience, reduce workplace stress,
                    and drive meaningful organizational change. Log in to unlock your team’s potential and build
                    a thriving workplace.
                  </p>
                </Col>
                <Col sm={6} className="mx-auto">
                  <div className="resetCard loginCard">
                    <div className="login-header reset-header">
                      <h3 className="fs-4 text-black mb-1">Reset Your Password</h3>
                      <p>Enter your new password below.</p>
                    </div>
                    <div className="reset-body login-bady">
                      <Form className="mt-0" noValidate onSubmit={handleSubmit(handleResetPassword)}>
                        {/* Password Field */}
                        <FormGroup controlId="inputPassword" className="my-3">
                          <FormLabel>New Password</FormLabel>
                          <div className="position-relative">
                            <FormControl
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Enter new password"
                              isInvalid={!!errors.password}
                              {...register('password', {
                                required: 'Password is required',
                                minLength: {
                                  value: 6,
                                  message: 'Password must be at least 6 characters long',
                                },
                              })}
                            />
                            <i
                              className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`}
                              onClick={togglePasswordVisibility}
                              title="Toggle Password Visibility"
                              style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {errors.password?.message}
                          </Form.Control.Feedback>
                        </FormGroup>

                        {/* Confirm Password Field */}
                        <FormGroup controlId="inputConfirmPassword" className="my-3">
                          <FormLabel>Confirm Password</FormLabel>
                          <div className="position-relative">
                            <FormControl
                              type={showConfirmPassword ? 'text' : 'password'}
                              placeholder="Confirm new password"
                              isInvalid={!!errors.confirmPassword}
                              {...register('confirmPassword', {
                                required: 'Please confirm your password',
                                validate: (value) => value === getValues('password') || 'Passwords do not match',
                              })}
                            />
                            <i
                              className={`bi ${showConfirmPassword ? 'bi-eye-slash' : 'bi-eye'}`}
                              onClick={toggleConfirmPasswordVisibility}
                              title="Toggle Confirm Password Visibility"
                              style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {errors.confirmPassword?.message}
                          </Form.Control.Feedback>
                        </FormGroup>

                        {/* Submit Button */}
                        <Button className="w-100 btn btn-lg btn-primary mt-3" type="submit" disabled={isLoading}>
                          {isLoading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          <span className="px-2">Reset Password</span>
                        </Button>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <ToastContainer />
      </main>
    </>
  );
}

export default ResetPassword;
