import { Outlet, useLocation, Navigate } from 'react-router-dom';

import Footer from '../components/Footer';
import AdminNavbar from '../components/admin/AdminNavbar';
import useAuth from '../hooks/useAuth';

function AdminLayout() {
  const auth = useAuth();
  const { pathname } = useLocation();

  if (auth.isAuth()) {
    return (
      <>
        <AdminNavbar />
        <Outlet />
        <Footer />
      </>
    );
  }

  return <Navigate to={`/login?redirect=${encodeURIComponent(pathname)}`} replace />;
}

export default AdminLayout;
