import { NavLink, useNavigate } from 'react-router-dom';
import { Container, Dropdown } from 'react-bootstrap';
import useAuth from '../../hooks/useAuth';

import { logout } from '../../services/AuthService';

import './admin-navbar.css';
import logo from '../../assets/images/home/logo.png';

const AdminNavbar = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth.getSession();
  const items = [
    { path: '/auth/dashboard', title: 'My Dashboard' },
    { path: '/auth/profilometer', title: 'Profilometer' },
    { path: '/auth/mentalbalance', title: 'Mental Balance' },
    { path: '/auth/workbalance', title: 'Work Balance' },
  ];

  const handleLogout = async (e) => {
    e.preventDefault();

    await logout();
    navigate('/');
  };

  const getFirstLetter = (string) => string.charAt(0).toUpperCase();

  return (
    <nav className="navbar adminNavbar navbar-expand-lg py-2 border-bottom">
      <Container>
        <a className="navbar-brand p-0" href="/">
          <img src={logo} alt="" className="navbar-logo" />
        </a>
        <button className="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarMenu"
                aria-controls="navbarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"/>
        </button>
        <div className="collapse navbar-collapse" id="navbarMenu">
          <div className="d-flex menu justify-content-between align-items-center">
            <ul className="navbar-nav">
              {
                items.map((item, i) => (
                  <li key={i} className="nav-item">
                    <NavLink className="nav-link" to={item.path}>{item.title}</NavLink>
                  </li>
                ))
              }
            </ul>
            <div className="userProfile">
              <div className="d-flex align-items-center">
                <span className="userIcon">{getFirstLetter(user?.name)}{getFirstLetter(user?.last_name)}</span>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {user?.name} {user?.last_name} <i className="bi bi-chevron-down"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href={'/auth/profile'}>Profile</Dropdown.Item>
                    <Dropdown.Item href={'/auth/dashboard'}>My Dashboard</Dropdown.Item>
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </nav>
  );
};

export default AdminNavbar;
