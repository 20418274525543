import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Container, Col, Row, Image, Accordion, Figure,
} from 'react-bootstrap';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
// Microsoft login
import { useMsal } from '@azure/msal-react';
// import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { loginRequest } from '../../msalConfig';

import './login.css';
import microsoft from '../../assets/images/login/microsoft.png';
import googleIcon from '../../assets/images/login/googleIcon.png';
import { socialLogin, login, microsoftLogin } from '../../services/AuthService';

const LoginPage = () => {
  const title = 'Login';
  const navigate = useNavigate();
  const { instance } = useMsal();

  const [formData, setFormData] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [isEyeVisible, setIsEyeVisible] = useState(true);

  const handleLogin = async () => {
    try {
      // Perform the login
      const response = await instance.loginPopup(loginRequest);
      const { idToken } = response;
      const decoded = parseJwt(idToken);
      // Call your backend API to process login
      const backendResponse = await microsoftLogin({
        name: decoded.name,
        email: decoded.preferred_username,
        provider: 'microsoft', // Fixed provider name
        access_token: idToken, // Send the ID token
      });
      if (backendResponse.success) {
        toast.success(backendResponse.message); // Notify success
        navigate('/auth/dashboard'); // Navigate to dashboard
      } else {
        toast.error(backendResponse.message); // Notify error from backend
      }
    } catch (error) {
      toast.error('Some error occurred. Please try again.');
    }
  };

  // Helper function to decode JWT tokens
  const parseJwt = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join(''),
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      return null;
    }
  };

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value, // Dynamically set the field based on the name attribute
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { email: '', password: '' };

    // Email validation
    if (!formData.email) {
      newErrors.email = 'Email is required.';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Enter a valid email address.';
      isValid = false;
    }

    // Password validation
    if (!formData.password) {
      newErrors.password = 'Password is required.';
      isValid = false;
    } else if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await login(formData.email, formData.password);

        if (response.success) {
          navigate('/auth/dashboard');
        } else {
          toast.error(response.message || 'Some error occurred. Please try again.');
        }
      } catch (error) {
        toast.error('Some error occured. Please try again.');
      }
    }
  };

  const handleLoginSuccess = async (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    try {
      const response = await socialLogin({
        name: decoded.given_name,
        last_name: decoded.family_name,
        email: decoded.email,
        email_verified: decoded.email_verified,
        expiry_date: decoded.exp,
        provider: 'google',
        access_token: credentialResponse.credential,
        avatar: decoded.picture,
      });
      if (response.success) {
        // toast.success(response.message);
        navigate('/auth/dashboard');
      } else {
        // console.log(response.message);
        toast.error(response.message);
      }
    } catch (error) {
      // console.log(error.message);
      toast.error('Some error occured. Please try again.');
    }
  };

  const handleLoginError = () => {
    toast.error('Authentication failed.');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setIsEyeVisible(true); // Hide the eye icon when clicked
  };

  useEffect(() => {
    // Check if there's a message in localStorage
    const toastMessage = localStorage.getItem('toastMessage');
    if (toastMessage) {
      const { variant, message } = JSON.parse(toastMessage);
      if (variant === 'success') {
        toast.success(message);
      } else {
        toast.error(message);
      }
      // Clear the message from localStorage
      localStorage.removeItem('toastMessage');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main>
        <div className="login-page">
          <div className="loginForm backgroundBg">
            <Container>
              <Row className="align-items-center">
                <Col sm={6}>
                  <h2 className="heading">Let’s empower</h2>
                  <h3 className="subHeading">your workforce</h3>
                  <p className="para">Access tools and insights designed to enhance mental resilience, reduce workplace stress,
                    and drive meaningful organizational change. Log in to unlock your team’s potential and build
                    a thriving workplace.
                  </p>
                </Col>
                <Col sm={6}>
                  <div className="loginCard">
                    <div className="login-header">
                      <p>
                        <Image src={googleIcon} className="img-fluid" />
                        Sign in with Google / Microsoft Outlook
                      </p>
                    </div>
                    <div className="login-bady">
                      <h3>Sign in</h3>
                      <p className="text-muted">Welcome to Your Journey Toward Mental Wellness</p>
                      {/* Email and Password Login */}
                      <form onSubmit={handleFormSubmit}>
                        <div className="form-group">
                          <label htmlFor="email">Email Address</label>
                          <input
                            type="email"
                            id="email"
                            name="email" // Important: Matches the key in formData
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleInputChange}
                          />
                          {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                        </div>
                        <div className="form-group my-2">
                          <label htmlFor="password">Password</label>
                          <div className="input-group">
                          <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            name="password"
                            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                            placeholder="Enter your password"
                            value={formData.password}
                            onChange={handleInputChange}
                          />
                          {/* Eye icon to show/hide password */}
                          {isEyeVisible && (
                            <i
                              className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`} // Show/Hide eye icon
                              onClick={togglePasswordVisibility}
                              style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                                zIndex: 1, // Ensure the icon is above the input field
                              }}
                            />
                          )}
                          </div>
                          {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                        </div>
                        <div className="form-group d-flex align-items-center justify-content-between loginLinks">
                          <button type="submit" className="btn btn-primary btn-block">
                            Sign In
                          </button>
                          <Link to={'/forgot-password'}>Forgot your password?</Link>
                        </div>
                      </form>
                      {/* Divider */}
                      <div className="divider my-4 position-relative">
                        <hr className="my-0" />
                        <span>or</span>
                      </div>
                      {/* Social Login */}
                      {/* Microsoft Login */}
                      {/* <button className="microsoftBtn mb-2" onClick={microsoftLogin}>
                        <Figure className="mb-0">
                          <Figure.Image
                            width={15}
                            height={15}
                            alt="171x180"
                            src={microsoft}
                            className="mb-0"
                          />
                        </Figure>Sign up with Microsoft
                      </button> */}
                      {/* LOgin Access */}
                      <Dropdown.Item as="button" className="microsoftBtn mb-2" onClick={() => handleLogin()}>
                        <Figure className="mb-0">
                            <Figure.Image
                              width={15}
                              height={15}
                              alt="171x180"
                              src={microsoft}
                              className="mb-0"
                            />
                          </Figure>Sign up with Microsoft
                      </Dropdown.Item>

                      <GoogleLogin
                        onSuccess={handleLoginSuccess}
                        onError={handleLoginError}
                      />
                      <button className="microsoftBtn mt-2"><i className="bi bi-apple"></i>Sign up with Apple</button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="theConnect">
            <Container>
              <Row>
                <Col>
                  <h3 className="subHeading text-white"><span className="heading text-white">Connect</span> the dots</h3>
                  <p>Engagement. Development. Performance. Growth. Retention.
                    They’re absolutely vital to <br /> business,
                    but other tools won’t tell you the full story. Ready to go deeper?</p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="faqSec py-5">
            <Container>
              <Row>
                <Col>
                  <h3 className="subHeading text-center pb-4">
                    Frequently asked <span className="heading">questions</span>
                  </h3>
                  <div className="text-start px-5 my-2 faqSec">
                    <h4 className="pb-3 text-black">General Questions</h4>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="text-black">Q: What is Malance?</Accordion.Header>
                        <Accordion.Body>
                          A: Malance is a mental wellness platform designed exclusively for corporate
                          organizations. It helps reduce employee stress, improve productivity, and foster
                          a balanced work environment through tools like Profilometer, Mental Balance, and
                          Work Balance.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className="text-black">Q: Who can use Malance?</Accordion.Header>
                        <Accordion.Body>
                          A: Malance is specifically tailored for businesses and organizations looking to
                          enhance employee well-being and productivi
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header className="text-black">
                          Q: How can my company benefit from Malance?
                        </Accordion.Header>
                        <Accordion.Body>
                          A: Malance provides insights into employee mental health, offers stress management
                          tools, and tracks work balance. These tools enable businesses to create a healthier,
                          more productive workforce.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <h4 className="pb-3 mt-1 pt-5 text-black">Features and Functionality</h4>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="text-black">Q: What is the Profilometer?</Accordion.Header>
                        <Accordion.Body>
                          A: The Profilometer generates comprehensive employee profiles by analyzing their
                          motivations, talents, and aspirations. It provides actionable insights to optimize
                          task assignments and improve employee performance.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className="text-black">Q: How does the Mental Balance feature work?</Accordion.Header>
                        <Accordion.Body>
                          A: Mental Balance assesses stress levels through quizzes and provides tailored
                          recommendations, mindfulness practices, and stress management strategies to help
                          employees maintain a balanced mental state.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header className="text-black">Q: What does the Work Balance feature include?</Accordion.Header>
                        <Accordion.Body>
                          A: Work Balance helps employees manage tasks, track deadlines, and analyze productivity trends.
                          It also provides suggestions to improve workflow efficiency.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <h4 className="pb-3 mt-1 pt-5 text-black">Technical Details</h4>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="text-black">Q: Is Malance available on mobile?</Accordion.Header>
                        <Accordion.Body>
                          A: Yes, Malance is fully responsive and accessible on all devices, including smartphones
                          and tablets.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className="text-black">Q: How secure is the platform?</Accordion.Header>
                        <Accordion.Body>
                          A: Malance prioritizes data security by using encryption for data in transit and at rest,
                          complying with privacy regulations like GDPR, and providing users control over their data.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <h4 className="pb-3 mt-1 pt-5 text-black">Corporate-Specific Questions</h4>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="text-black">Q: Can we customize the platform for our organization?</Accordion.Header>
                        <Accordion.Body>
                          A: Yes, Malance offers customizable dashboards and tailored recommendations to align with
                          your organization’s needs.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className="text-black">Q: How does Malance track employee well-being without invading privacy?</Accordion.Header>
                        <Accordion.Body>
                            A: Malance uses anonymized and consent-based data collection methods to provide
                            insights while respecting individual privacy.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header className="text-black">
                          Q: What kind of analytics does Malance provide to employers?
                        </Accordion.Header>
                        <Accordion.Body>
                          A: Employers can access aggregated insights about employee well-being and productivity
                          trends, helping to make informed decisions to support their teams.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <h4 className="pb-3 mt-1 pt-5 text-black">Support and Assistance</h4>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="text-black">Q: How do I get started with Malance?</Accordion.Header>
                        <Accordion.Body>
                          A: Contact us via email at care@malance.com to schedule a demo or discuss how Malance
                          can fit your organization’s needs.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className="text-black">Q: Where can I get help if I encounter an issue?</Accordion.Header>
                        <Accordion.Body>
                          A: For any technical or general inquiries, email us at care@malance.com, and our support
                          team will assist you promptly.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header className="text-black">Q: Does Malance provide training for using the platform?</Accordion.Header>
                        <Accordion.Body>
                            A: Yes, we offer onboarding support and training sessions to ensure seamless
                            implementation for your team.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <ToastContainer />
      </main>
    </>
  );
};

export default LoginPage;
