import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Container, Dropdown } from 'react-bootstrap';
import useAuth from '../hooks/useAuth';

import { logout } from '../services/AuthService';

import './navbar.css';
import logo from '../assets/images/home/logo.png';

const Navbar = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth.getSession();

  const items = [
    { path: '/', title: 'Home' },
    { path: '/therapy', title: 'Therapy' },
    { path: '/how-it-works', title: 'How It Works' },
    { path: '/faq', title: 'FAQ' },
    { path: '/contact', title: 'Contact us' },
  ];
  const handleLogout = async (e) => {
    e.preventDefault();
    await logout();
    navigate('/');
  };

  const getFirstLetter = (string) => string.charAt(0).toUpperCase();

  return (
    <nav className="navbar adminNavbar navbar-expand-lg py-2 border-bottom">
      <Container>
        <Link className="navbar-brand p-0" to="/">
          <img src={logo} alt="" className="navbar-logo" />
        </Link>
        <button className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMenu"
          aria-controls="navbarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarMenu">
          <div className="menu d-flex justify-content-between align-items-center">
            <ul className="navbar-nav">
              {
                items.map((item, i) => (
                  <li key={i} className="nav-item">
                    <NavLink className="nav-link" to={item.path}>{item.title}</NavLink>
                  </li>
                ))
              }
            </ul>
            {auth.isAuth() ? (
              <div className="userProfile">
                <div className="d-flex align-items-center">
                  <span className="userIcon">{getFirstLetter(user?.name)}{getFirstLetter(user?.last_name)}</span>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {user?.name} {user?.last_name} <i className="bi bi-chevron-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href={'/auth/profile'}>Profile</Dropdown.Item>
                      <Dropdown.Item href={'/auth/dashboard'}>My Dashboard</Dropdown.Item>
                      <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            ) : (
              <Link to={'/login'} className="loginBtn d-flex align-items-center btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                </svg>
                <span className="mx-2">Sign in</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                </svg>
              </Link>
            )}
          </div>
        </div>
      </Container>
    </nav>
  );
};

export default Navbar;
