import { lazy, Suspense } from 'react';
import {
  BrowserRouter, Navigate, Routes, Route,
} from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AuthProvider from '../hooks/AuthProvider';

// Layouts
import AuthLayout from '../layouts/AuthLayout';
import AdminLayout from '../layouts/AdminLayout';
import OtherLayout from '../layouts/OtherLayout';

// Auth
import LoginPage from '../pages/Auth/Login';
import AuthCallback from '../pages/Auth/AuthCallback';
import LogoutPage from '../pages/Auth/Logout';
import SignupPage from '../pages/Auth/Signup';
import ForgotPasswordPage from '../pages/Auth/ForgotPassword';
import ResetPasswordPage from '../pages/Auth/ResetPassword';
import NotFoundPage from '../pages/NotFound';

// Other
const HomePage = lazy(() => import('../pages/Home'));
const TherapyPage = lazy(() => import('../pages/Therapy'));
const HowItWorksPage = lazy(() => import('../pages/HowItWorks'));
const BlogPage = lazy(() => import('../pages/Blog'));
const FaqPage = lazy(() => import('../pages/Faq'));
const ContactPage = lazy(() => import('../pages/Contact'));
const AboutPage = lazy(() => import('../pages/About'));
const TestPage = lazy(() => import('../pages/Test/Index'));
const TestResultPage = lazy(() => import('../pages/Test/Result/TestResult'));
const TestResultSuccessPage = lazy(() => import('../pages/Test/Result/TestResultSuccessPage'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const TermsServices = lazy(() => import('../pages/TermsOfService'));

// After Auth
const Profile = lazy(() => import('../pages/Admin/Profile'));
const Dashboard = lazy(() => import('../pages/Admin/Dashboard'));
const Profilometer = lazy(() => import('../pages/Admin/Profilometer'));
const MentalBalance = lazy(() => import('../pages/Admin/MentalBalance'));
const WorkBalance = lazy(() => import('../pages/Admin/WorkBalance'));
const PersonalityTypeDetail = lazy(() => import('../pages/Admin/PersonalityTypeDetail'));

function App() {
  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId="59211675337-vbncuimsuc7u78e6p00hjg5r41skdvfi.apps.googleusercontent.com">
        <AuthProvider>
          <Routes>
            <Route path="/" element={<AuthLayout />}>
              <Route path="/auth/callback" element={<AuthCallback />} />
              <Route
                path=""
                element={<Suspense fallback={<>...</>}>
                    <HomePage />
                  </Suspense>
                }
              />
              <Route
                path="home"
                element={<Suspense fallback={<>...</>}>
                    <HomePage />
                  </Suspense>
                }
              />
              <Route
                path="therapy"
                element={<Suspense fallback={<>...</>}>
                    <TherapyPage />
                  </Suspense>
                }
              />
              <Route
                path="how-it-works"
                element={<Suspense fallback={<>...</>}>
                    <HowItWorksPage />
                  </Suspense>
                }
              />
              <Route
                path="blog"
                element={<Suspense fallback={<>...</>}>
                    <BlogPage />
                  </Suspense>
                }
              />
              <Route
                path="faq"
                element={<Suspense fallback={<>...</>}>
                    <FaqPage />
                  </Suspense>
                }
              />
              <Route
                path="contact"
                element={<Suspense fallback={<>...</>}>
                    <ContactPage />
                  </Suspense>
                }
              />
              <Route
                path="about"
                element={<Suspense fallback={<>...</>}>
                    <AboutPage />
                  </Suspense>
                }
              />
              <Route path="login" element={<LoginPage />} />
              <Route path="logout" element={<LogoutPage />} />
              <Route path="signup" element={<SignupPage />} />
              <Route path="forgot-password" element={<ForgotPasswordPage />} />
              <Route path="reset-password/:token" element={<ResetPasswordPage />} />
              <Route path="not-found" element={<NotFoundPage />} />
              <Route path="*" element={<Navigate to="/not-found" />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="terms-of-service" element={<TermsServices />} />
            </Route>
            <Route path="auth" element={<AdminLayout />}>
              <Route
                path=""
                element={<Suspense fallback={<>...</>}>
                    <Dashboard />
                  </Suspense>
                }
              />
              <Route
                path="dashboard"
                element={<Suspense fallback={<>...</>}>
                    <Dashboard />
                  </Suspense>
                }
              />
              <Route
                path="profilometer"
                element={<Suspense fallback={<>...</>}>
                    <Profilometer />
                  </Suspense>
                }
              />
              <Route
                path="mentalbalance"
                element={<Suspense fallback={<>...</>}>
                    <MentalBalance />
                  </Suspense>
                }
              />
              <Route
                path="workbalance"
                element={<Suspense fallback={<>...</>}>
                    <WorkBalance />
                  </Suspense>
                }
              />
              <Route
                path="personality-detail/:type"
                element={<Suspense fallback={<>...</>}>
                    <PersonalityTypeDetail />
                  </Suspense>
                }
              />
              <Route
                path="profile"
                element={<Suspense fallback={<>...</>}>
                    <Profile />
                  </Suspense>
                }
              />
              <Route
                path="test/result/:testResultId"
                element={<Suspense fallback={<>...</>}>
                    <TestResultPage />
                  </Suspense>
                }
              />
            </Route>
            <Route path="auth/test" element={<OtherLayout />}>
              <Route
                path=""
                element={<Suspense fallback={<>...</>}>
                    <TestPage />
                  </Suspense>
                }
              />
              <Route
                path="success/:id"
                element={<Suspense fallback={<>...</>}>
                    <TestResultSuccessPage />
                  </Suspense>
                }
              />
            </Route>
          </Routes>
        </AuthProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
}

export default App;
