// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-signup {
  width: 100%;
  max-width: 600px;
  padding: 15px;
  margin: auto;
}

.container-signup .form-label {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Auth/signup.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container-signup {\n  width: 100%;\n  max-width: 600px;\n  padding: 15px;\n  margin: auto;\n}\n\n.container-signup .form-label {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
