import React from 'react';
import { createRoot } from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.esm.min';

import App from './app/App';
import { msalConfig } from './msalConfig';

const msalInstance = new PublicClientApplication(msalConfig);

const root = createRoot(document.getElementById('root'));

root.render(
<MsalProvider instance={msalInstance}>
    <App />
</MsalProvider>,
);
