import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Button, Form, FormGroup, FormControl, FormLabel, Spinner,
} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { emailPattern } from '../../common/constants';
import { sendForgotPassword } from '../../services/AuthService';

import './login.css';

function ForgotPassword() {
  const title = 'Forgot Password';

  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleSend = async (data) => {
    try {
      setIsLoading(true);
      const response = await sendForgotPassword(data);
      if (response.success) {
        toast.success(response.message);
        reset({ email: '' });
        setIsLoading(false);
      } else {
        toast.error(response.message || 'An error occurred. Please try again.');
        setIsLoading(false);
      }
    } catch (err) {
      toast.error('Some error occured. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main>
        <div className="forGotPassword">
          <div className="loginForm backgroundBg">
            <Container>
              <Row className="align-items-center">
              < Col sm={6}>
                  <h2 className="heading">Let’s empower</h2>
                  <h3 className="subHeading">your workforce</h3>
                  <p className="para">Access tools and insights designed to enhance mental resilience, reduce workplace stress,
                    and drive meaningful organizational change. Log in to unlock your team’s potential and build
                    a thriving workplace.
                  </p>
                </Col>
                <Col sm={6}>
                  <div className="loginCard">
                    <div className="login-header">
                      <p>
                        <i class="bi bi-lock-fill me-1"></i>
                        Enter your email so that we can send a password reset to you.
                      </p>
                    </div>
                    <div className="login-bady">
                      <Form className="row g-2" noValidate onSubmit={handleSubmit(handleSend)}>
                        {/* Email Field */}
                        <FormGroup controlId="inputEmail">
                          <FormLabel>Email</FormLabel>
                          <FormControl
                            type="email"
                            placeholder="Email@domain.com"
                            isInvalid={!!errors.email}
                            {...register('email', {
                              required: 'Email is required',
                              pattern: {
                                value: emailPattern,
                                message: 'Invalid email address',
                              },
                            })}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email && errors.email.message}
                          </Form.Control.Feedback>
                        </FormGroup>

                        {/* Submit Button */}
                        <Button
                          className="w-100 btn btn-lg btn-primary mt-3"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          <span className="px-2">Reset Password</span>
                        </Button>
                        <p className="mt-4 backToLogin"><Link to="/login"><i className="bi bi-arrow-left"></i> Back to login</Link></p>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <ToastContainer />
      </main>
    </>
  );
}

export default ForgotPassword;
