import { Outlet, useLocation, Navigate } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

function OtherLayout() {
  const auth = useAuth();
  const { pathname } = useLocation();

  if (auth.isAuth()) {
    return (
      <>
        <Outlet />
      </>
    );
  }

  return <Navigate to={`/login?redirect=${encodeURIComponent(pathname)}`} replace />;
}

export default OtherLayout;
