// AuthCallback.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

const AuthCallback = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  useEffect(() => {
    const handleRedirectCallback = async () => {
      try {
        const response = await instance.handleRedirectPromise();
        if (response) {
          navigate('/dashboard');
        }
      } catch (error) {
        console.log(error);
      }
    };

    handleRedirectCallback();
  }, [instance, navigate]);

  return <div>Loading...</div>;
};

export default AuthCallback;
